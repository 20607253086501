import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Dropdown, Form } from 'react-bootstrap';
import Logo from './logo.png';
import { GetSubscriptionOnDemand, WebgetDataforDispatch } from './API';
import { Combobox } from "react-widgets"
import DataTable from 'react-data-table-component'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Header from "./Header"
let VoLdata = '';

function Dispatch() {
  const User = Cookies.get('#4Fu!');
  var websearchtype = 'BySubScription',
    websearchText = '',
    websearchTextM = '',
    Searchsubjectlist = '',
    SearchsubjectlistID = '',
    PageNum = 1,
    PageSize = 10;
  var CategoryID = '',
    SiteName = 'BookStore',
    SchoolCode = '';
  const [mySearchList, setmySearchList] = useState([]);
  const [searching, setSearching] = useState();
  const [isSearchListVisible, setIsSearchListVisible] = useState(true);
  const [ISBN, setISBN] = useState("");
  const today = new Date().toISOString().split('T')[0];

  // Initialize state with today's date

  const [FromDate, setFromDate] = useState('');
  const [ToDate, setToDate] = useState(today);
  const [volumn, setVolumn] = useState([]);
  const [ShipDate, setShipDate] = useState(today);
  const [EstDate, setEstDate] = useState(today);
  const [Remark, setRemark] = useState([]);
  const [ShipRef, setShipRef] = useState([]);
  const [Deliver, setDeliver] = useState(0)
  const [IsEBook, setIsEBook] = useState([])

  const [Issue, setIssue] = useState([]);
  const [sIssue, setsIssue] = useState([]);
  const [GridData, setGridData] = useState([]);

  if (User === null || User === undefined || User === '') {
    window.location.href = '/';
  }

  const TrAarray = [];

  const columns = [
    { name: 'Dispatch ID', selector: 'SubscriptionID' },
    { name: 'Magazine', selector: 'BookName' },
    { name: 'Volumn', selector: 'VolumeDescription' },    
    { name: 'Issue', selector: 'IssueDescription' },
    { name: 'Issue Date', selector: 'IssueDate' },
    { name: 'Ref Number', selector: 'BookingRef' },
    { name: 'Booking Date', selector: 'ShipmentBookingDate' },
    { name: 'Dispatch On', selector: 'DispatchCreatedon' },
    { name: 'Customer Name', selector: 'CUSTOMERNAME' },

    {
      name: 'Delivery Address',
      cell: (row) => `${row.ADDRESS}, ${row.Phone},${row.CITY}, ${row.PostalCode}`,
    },
    { name: 'Phone', selector: 'Phone' },
    { name: 'City', selector: 'CITY' },
    { name: 'PostalCode', selector: 'PostalCode' },
  ];
  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };


  const [TrID, setTrID] = useState([]);


  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [Row, setRow] = useState([]);
  const [loaD, setloaD] = useState([]);


  useEffect(() => {

    websearchText = ""
    GetSubscriptionOnDemand(websearchtype, websearchText, CategoryID, SiteName, SchoolCode, PageNum, PageSize).then(
      (result) => {
        setmySearchList(result.data);

        if (result.data[0].Result === 'Failed') {
          let inpValue = document.getElementById('dv_SearchList');
          inpValue.style.display = 'none';
          setmySearchList([]);
          console.log(result.data)
        } else {
          let inpValue = document.getElementById('dv_SearchList');
          inpValue.style.display = 'block';
          setSearching(true);
          console.log(result.data)
        }

        if (websearchText === '') {
          setmySearchList([]);
        }
      }
    );
    console.log(ISBN, Deliver)

  }, [])

  const handleSelectedRowsChange = (selectedRows) => {

    setRow(selectedRows);

    if (selectedRows && selectedRows.selectedRows) {
      const selectedRowDetails = selectedRows.selectedRows;
      console.log("Selected Row Details:", selectedRowDetails);
      const selectedOrderUniqueIDs = selectedRowDetails.map((row) => row.Subscription_Dispatch_ID);
      // setSelectedCheckboxes(selectedRowDetails);
      setTrID(selectedOrderUniqueIDs);
    } else {
      console.error("Selected Rows data is undefined or has an unexpected structure");
    }
  };

  useEffect(() => {
    console.log(TrID);
  }, [TrID]);

  const handler = async (event) => {
    let inpValue = document.getElementById('txtSearch');
    websearchText = inpValue.value;

    if (event === '' || event === null || event === undefined) {
      setSearching(inpValue.value);
      window.location.href = 'book-shop-search?q=' + websearchText;
    } else {
      if (event.key === 'Enter') {
        websearchText = event.target.value.toLowerCase();
        GetSubscriptionOnDemand(websearchtype, websearchText, CategoryID, SiteName, SchoolCode, PageNum, PageSize).then(
          (result) => {
            setmySearchList(result.data);

            if (result.data[0].Result === 'Failed') {
              let inpValue = document.getElementById('dv_SearchList');
              inpValue.style.display = 'none';
              console.log(result.data)
              setmySearchList([]);
            } else {
              let inpValue = document.getElementById('dv_SearchList');
              inpValue.style.display = 'block';
              console.log(result.data)
              setSearching(true);
            }


          }
        );


      } else {
        websearchText = event.target.value.toLowerCase();
        GetSubscriptionOnDemand(websearchtype, websearchText, CategoryID, SiteName, SchoolCode, PageNum, PageSize).then(
          (result) => {
            setmySearchList(result.data);

            if (result.data[0].Result === 'Failed') {
              let inpValue = document.getElementById('dv_SearchList');
              inpValue.style.display = 'none';
              setmySearchList([]);
              console.log(result.data)
            } else {
              let inpValue = document.getElementById('dv_SearchList');
              setval(String(result.data[0].Perioidcal_VolumeId));
              inpValue.style.display = 'block';
              setSearching(true);
              console.log(result.data)
            }

            if (websearchText === '') {
              setmySearchList([]);
            }
          }
        );
      }
    }
  };
  function IssueBind(volumn) {
    WebgetDataforDispatch('getISSUE', ISBN, volumn, FromDate, ToDate, '', '', '', '', '', '').then(
      (result) => {
        console.log('Result from getISSUE:', result);
        if (result.data !== "Failed to load getISSUE List") {
          console.log('Setting Issue state:', result.data);
          setIssue(result.data);
        } else {
          console.log('Setting Issue state to empty array');
          setIssue([]);
        }
      }
    );
  }

  const ShipDateChange = (e) => {
    setShipDate(e.target.value);
  };
  const ESTChange = (e) => {
    setEstDate(e.target.value);
  };

  // Event handler for date change
  const FromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const ToDateChange = (e) => {
    setToDate(e.target.value);
  };


  const ShipRemark = (e) => {
    setToDate(e.target.value);
  };


  const ShipREfChange = (e) => {
    setShipRef(e.target.value);
  };


  const handleSelectItem = (selectedISBN) => {
    setISBN(selectedISBN);
    setIsSearchListVisible(false);
    setVolumn([]);
    WebgetDataforDispatch('getVolumn', selectedISBN, '', '', '', '', '', '', '', '').then(
      (result) => {
        if (result.data !== "Failed to load getVolumn List") {
          console.log('Setting Volumn state:', result.data);
          setVolumn(result.data);
        } else {
          console.log('Setting Volumn state to empty array');
          setVolumn([]);
        }
      }
    );
  };

  const [val, setval] = useState('')

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  function shoFunction() {

    // if(volumn !== undefined  ){
    //   var Vdata = String(volumn[0].Perioidcal_VolumeId) 
    //   setval(String(volumn[0].Perioidcal_VolumeId));

    // }

    WebgetDataforDispatch('getDispatchGridData', FromDate, ToDate, ISBN, '', '', sIssue, Deliver, '', '', '', '', '').then(

      (result) => {
        console.log(result.data)
        if (result.data !== "Failed to load getDispatchGridData List") {
          console.log('Setting Volumn state:', result.data);
          setGridData(result.data);
          setLoading(false);
        } else {
          console.log('Setting Volumn state to empty array');
          setGridData([]);
          setVolumn([]);

          setIssue([]);

        }
      }
    );


  }


  const [Name, setName] = useState([])

  const clearSelectedRows = () => {
    if (GridData.current) {
      GridData.current.clearSelectedRows();
    }
  };


  function Undispatch() {

    if (ShipRef === null || ShipRef === undefined || ShipRef === '') {
      alert("ShipRef not be blank ! ");
      return;
    }

    if (ShipDate === null || ShipDate === undefined || ShipDate === '') {
      alert("ShipDate not selected ! ");
      return;
    }

    if (Name === null || Name === undefined || Name === '') {
      alert("Name not be blank ! ");
      return;
    }

    if (EstDate === null || EstDate === undefined || EstDate === '') {
      alert("EstDate not selected ! ");
      return;
    }


    WebgetDataforDispatch('udispatch', TrID, val, sIssue, 0, ShipRef, '', '', '', '', '')
      .then((updateInfoResult) => {
        console.log('UpdateInfo', TrID, val, sIssue, ShipRef, ShipDate, Name, EstDate,)
        console.log(updateInfoResult.data);
        if (updateInfoResult.data !== "Failed to load udispatch List") {
          console.log("Undispatch", updateInfoResult.data);
          setGridData(updateInfoResult.data);
          setSelectedCheckboxes([])
          setRow([])
          // Second API call to 'getDispatchGridData'
          return WebgetDataforDispatch('getDispatchGridData', FromDate, ToDate, ISBN, '', val, sIssue, Deliver, '', '', '', '', '');
        } else {
          console.log('Setting Volumn state to empty array');
          setSelectedCheckboxes([])
          setRow([])
          setShipRef([])
          setName([])
          return Promise.reject(updateInfoResult.data);
        }
      })
      .then((getDispatchGridDataResult) => {
        if (getDispatchGridDataResult.data !== "Failed to load getDispatchGridData List") {
          console.log('Setting Volumn state:', getDispatchGridDataResult.data);
          setGridData(getDispatchGridDataResult.data);
          setSelectedCheckboxes([])
          setRow([])
          setShipRef([])
          setName([])
        } else {
          console.log('Setting Volumn state to empty array');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle errors here
      });
  }


  function updateInGrid() {
    // Validate inputs

    //   if (Deliver !== 1) {
    //     console.log("");  // Corrected the typo here
    // } else {
    //     if (ShipRef !== 0 ) {
    //         alert("Booking Reference Number Not To Be Null!");
    //         return false;
    //     }
    // }

    if (ShipRef === null || ShipRef === undefined || ShipRef === '') {
      alert("ShipRef not be blank ! ");
      return;
    }
    if (!Name) {
      alert("Name should not be blank!");
      return false;
    }

    if (!EstDate) {
      alert("EstDate not selected!");
      return false;
    }

    // First API call to 'UpdateInfo'
    WebgetDataforDispatch('UpdateInfo', TrID, val, sIssue, ShipRef, ShipDate, Name, EstDate, '', '', '')
      .then((updateInfoResult) => {
        console.log('UpdateInfo', TrID, val, sIssue, ShipRef, ShipDate, Name, EstDate);
        console.log(updateInfoResult.data);

        if (updateInfoResult.data !== "Failed to load UpdateInfo List") {
          console.log('Setting Volume state:', updateInfoResult.data);
          setGridData(updateInfoResult.data);
          {
            (Deliver !== 1) ? printSlip(updateInfoResult.data) :
            alert("Test")
     
          }


          // Second API call to 'getDispatchGridData'
          return WebgetDataforDispatch('getDispatchGridData', FromDate, ToDate, ISBN, '', val, sIssue, Deliver, '', '', '', '', '');
        } else {
          console.log('Setting Volume state to an empty array');
          clearSelectedRows();
          setRow([]);
          setShipRef([]);
          setName([]);
          return Promise.reject(updateInfoResult.data);
        }
      })
      .then((getDispatchGridDataResult) => {
        if (getDispatchGridDataResult.data !== "Failed to load getDispatchGridData List") {
          console.log('Setting Volume state:', getDispatchGridDataResult.data);
          setGridData(getDispatchGridDataResult.data);

          // You may want to clear selected rows, shipRef, and name here if needed
          clearSelectedRows();
          setRow([]);
          setShipRef([]);
          setName([]);
        } else {
          console.log('Setting Volume state to an empty array');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const printSlip = (PrintData) => {
    if (!Array.isArray(PrintData)) {
      console.error('Error: PrintData is not an array.');
      return;
    }
    if (Array.isArray(PrintData) && PrintData.length > 0 && PrintData[0].Result !== "Failed to load UpdateInfo List") {
      const printWindow = window.open('', '_blank');
      const printContent = `

   <html>
      <head>
        <title>Bhavisa Foundation Dispatch Slips</title>
        <style>
          body {
            font-family: 'Arial', sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 0;
            color: #333;
          }

          h1 {
            color: #333;
            text-align: center;
          }

          .slip-container {
            max-width: 600px;
            margin: 10px auto;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 10px;
            border-radius: 8px;
            box-sizing: border-box;
          }

          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
          }

          td {
            padding: 10px;
          }

          p {
            margin: 0;
          }

          h4 {
            font-weight: bold;
          }

          .small-text {
            font-size: 40%;
            font-weight: bold;
          }

          .dotted-box {
            border: 1px dotted #333;
            padding: 10px;
            margin-top: 10px;
          }
        </style>
      </head>
      <body>
        <h4>Bhavisa Foundation Dispatch Slip</h4>
        ${PrintData.map((item, index) => `
          <div class="slip-container dotted-box ">
            <table>
              <tr>
                <td class="small-text">Order ID: ${item.OrderUniqueID}
                <br></br>
                Dispatch date : ${item.DispatchCreatedon}
                </td>
              </tr>
               <tr>
                <td class="small-text">Volume : ${item.VolumeDescription}
                <br/>
                <br/>
                Issue Name: ${item.IssueDescription}
               
                </td>
              </tr>
              <tr>
              <td class="small-text">Customer Name: ${item.CUSTOMERNAME}
              <br></br>
              Delivery Address: ${item.ADDRESS},  ${item.CITY},  ${item.PostalCode}, ${item.Phone}</td>    
              </tr>
            </table>
          </div>
         ${((index + 1) % 2 === 0 && index !== selectedCheckboxes.length - 1) ? '<div style="page-break-after: always;"></div>' : ''}
        `).join('')}
      </body>
    </html>
  `;
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.print();
    } else if (Deliver === 0) {
      alert("Invalid print!");
      return false;
    }
    else {
      alert("Please select any row!");
      return false;
    }
  };
  function Logout() {
    Cookies.remove("#4Fu!");
    window.location.href = '/';
  }

  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <Header />
          </section>
          <br />
          <section className="content-inner bg-white">
            <div className="container ">
              <div className="boreder">
                <div className="col-lg-12  Expireproduct">

                  <div className="mb-1">
                    <label htmlFor={`formcontrolinput`} className="form-label">
                      From Date:
                    </label>
                    <input
                      type="date"
                      id="frdt"
                      className="form-control form-control-sm "
                      value={FromDate}
                      onChange={FromDateChange}
                    />
                  </div>
                  <div className="mb-1">
                    <label htmlFor={`formcontrolinput`} className="form-label">
                      To Date:
                    </label>
                    <input
                      type="date"
                      id="todt"
                      className="form-control form-control-sm"
                      value={ToDate}
                      onChange={ToDateChange}
                    />
                  </div>
                  <div className="mb-3 Expireproduct2" style={{ zIndex: '9999999 !important' }}>
                    <label htmlFor={`formcontrolinput`} className="form-label Expireproduct" >
                      ISBN: {ISBN} {volumn.map((data, Vo) => ((data.Frequency !== null || data.Frequency !== "" || data.Frequency !== undefined) ? <><p>Frequency: {data.Frequency}</p></> : <></>))}

                    </label>
                    <div >
                      <Combobox
                        data={mySearchList}
                        textField="Book_Code"
                        className="dataListColor"
                        textField="Book_Name"
                        dataKey='Book_Code'
                        filter="contains"
                        placeholder="Search ISBN Here"

                        // onChange={(value) => {
                        //   setISBN(Book_Code);
                        // }}
                        onSelect={(selectedValue) => {
                          setISBN(selectedValue.Book_Code);
                          handleSelectItem(selectedValue.Book_Code);
                        }}
                      />
                    </div>
                    {/* <input
                    id="txtSearch"
                    type="text"
                    onKeyUp={(e) => handler(e)}
                    className="form-control form-control-sm "
                    placeholder="Search ISBN Here"
                    value={ISBN}
                    onChange={(e) => {
                      setISBN(e.target.value);
                      setIsSearchListVisible(e.target.value !== '');

                    }}
                    autoComplete="off"
                    onKeyDown={(e) => handler(e)}
                  />
                  {isSearchListVisible && mySearchList.length !== 0 && (
                    <div className="dataResult mb-3" id="dv_SearchList">
                      {mySearchList.slice(0, 10).map((data, index) => (
                        <div className="dataItem" key={index}>
                          <div className="dataInfo" onClick={() => handleSelectItem(data.Book_Code)}>
                            
                            <div className="dataBookName">
                              <span className="textC"  >Book ISBN:- &nbsp;  {data.Book_Code} </span> 
                              <span className="textC"  >Book Name:- &nbsp;  {data.BookName} </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )} */}
                  </div>

                  <div className="mb-1">
                    <label htmlFor={`formcontrolinput`} className="form-label">
                      Volumn:
                    </label>

                    <Form.Select aria-label="India" onChange={(e) => IssueBind(e.target.value)}>
                      <option>---All---</option>
                      {volumn.map((data, Vo) => (
                        <option key={Vo} value={data.Perioidcal_VolumeId}>{data.VolumeDescription}</option>

                      ))}
                    </Form.Select>
                  </div>
                  <div className="mb-1">
                    <label htmlFor={`formcontrolinput`} className="form-label">
                      Issue:
                    </label>

                    <Form.Select aria-label="India" onChange={(e) => setsIssue(e.target.value)}  >
                      <option>---All---</option>
                      {Issue.map((data, iss) => (
                        <option key={iss} value={data.IssueID}>{data.IssueDescription}</option>

                      ))}
                    </Form.Select>
                  </div>
                  <div className="mb-3" >
                    <Form>
                      <Form.Check
                        className="form-check-input"

                        type="switch"
                        id="custom-switch"
                        label={Deliver === 1 ? "Dispatched" : "To be Dispatched"}
                        onChange={(e) => setDeliver(e.target.checked ? 1 : 0)}
                      />

                    </Form>
                  </div>
                  <div className="mb-3  middle" >
                    <button className="btn btn-primary btnhover " onClick={shoFunction}>Show Data</button> &nbsp;
                    <Link to="/Dispatch" target="_parent" className="btn btn-primary btnhover " title="Refresh" ><i class="fa fa-refresh" aria-hidden="true"></i></Link>
                  </div>
                </div>
                <div className="mb-3">

                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-xl-20">
                  <div className="table-responsive boreder"  >
                    <DataTable
                      title="Data For Dispatch For Create Slips"
                      columns={columns}
                      data={(GridData.length > 0 && GridData[0].Result !== "Failed to load getDispatchGridData List" && GridData !== undefined) ? GridData : ""}
                      customStyles={customStyles}
                      selectableRows
                      pagination
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[10, 20, 30]}
                      onSelectedRowsChange={handleSelectedRowsChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <div className="col-xl-20"></div>
                <div className="boreder ">
                  <div className="col-lg-12 col-md-13 Expireproduct">
                    {(Deliver === 1) ? <>

                      <div className="mb-3">
                        <label htmlFor={`formcontrolinput`} className="form-label">
                          Booking Reference Number:
                        </label>
                        <input
                          type="text"
                          id="frdt"
                          className="form-control form-control-sm"
                          value={ShipRef}
                          onChange={ShipREfChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor={`formcontrolinput`} className="form-label">
                          Dispatch Created By :
                        </label>

                        <input
                          type="text"

                          className="form-control form-control-sm"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </> : <>
                    </>}

                    <div className="mb-3">
                      <label htmlFor={`formcontrolinput`} className="form-label">
                        Shipment Booking Date:
                      </label>
                      <input
                        type="date"
                        id="frdt"
                        className="form-control form-control-sm"
                        value={ShipDate}
                        onChange={ShipDateChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor={`formcontrolinput`} className="form-label">
                        Estimated Date:
                      </label>
                      <input
                        type="date"
                        id="frdt"
                        className="form-control form-control-sm"
                        value={EstDate}

                        onChange={ESTChange}
                      />
                    </div>



                  </div>
                  <div className="col-lg-12 col-md-13 Expireproduct">
                    {(Deliver === 1) ? <>
                      <div className="mb-3">
                        <button className="btn btn-primary btnhover " onClick={updateInGrid}>Update in Data</button>
                        &nbsp;
                        <p>*Note: Please update the Booking Reference Number in the Dispatched data.</p>
                      </div>
                      <div className="mb-3">
                        <button className="btn btn-primary btnhover " onClick={Undispatch}>Undispatch</button>
                      </div>
                    </> : <>
                      <div className="mb-3">
                        <button className="btn btn-primary btnhover " onClick={updateInGrid}>Print Dispatch Slip</button>
                      </div>
                    </>}







                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Dispatch;
