// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login.js';
import Dispatch from './Dispatch.js';
import Test from './test.js';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Dispatch" element={<Dispatch />} />
        {/* <Route path="/test" element={<Test/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
