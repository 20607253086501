import React from 'react'
import Logo from './logo.png';
import Cookies from 'js-cookie';


function Header() {

    function Logout(){
        Cookies.remove("#4Fu!");
        window.location.href = '/';
      }
  return (
    <div>
       <section className="content-inner">
            <div className="container">
              <div className="borederH Expireproduct ">
                <div >
                <img className="img-thumbnail mx-auto d-block mb-2 " src={Logo} alt="logo" />
                </div>
                
                <div style={{paddingTop:'32px' , color:'#F76D01'}} >
                  <h2>
                    <strong>Dispatch Management Screen</strong>
                  </h2>
                 
                </div>
                <div  style={{paddingTop:'32px' , color:'#F76D01'}} >
                <button  className ="btn btn-primary btnhover midHeading" onClick={Logout}>Logout</button>
                </div>
               
              </div>
            
            </div>
          </section>
    </div>
  )
}

export default Header
 