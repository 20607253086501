import axios from "axios";
import APIConfig from './ApiConfig';
const APIURL = "https://ecommerceapi.cloudpub.in";//  "http://localhost:53042/";// "http://localhost:53042/";// =
//
const accessCode ="D3hOCnavkGg4MnmXqTxepnOtNwzus1zrJ04exblI32g="// "nzHuiN+VH5NpavRiHgESR9EihVGdsKg1MQDHdwODrNIhURyiOkWRPw9Uik1gtNw9";
//const accessCode2= "QUnfATBLxQWfzwxLe9+itWaMqWx8BpUFZ6ZwsqiwkP0=";
//const CompanyComponent = "https://ecommerceapi.cloudpub.in";
// const iCompanyID = sessionStorage.getItem("iCompanyID");
// const iBranchID = sessionStorage.getItem("iBranchID");
// const FinancialPeriod = sessionStorage.getItem("FinancialPeriod");
var CompID = sessionStorage.getItem("iCompanyID");
const iCompanyID = ((CompID === null || CompID ==="" || CompID === undefined)?1:CompID);
var BranchID = sessionStorage.getItem("iBranchID");
const iBranchID = ((BranchID === null || BranchID === "" || BranchID === undefined)?1:BranchID); 
const FinancialPeriod = "2023-2024"
// sessionStorage.getItem("FinancialPeriod");
// const strUserID  = localStorage.getItem("EmailID");
// const  SessionID  =localStorage.getItem('SessionID');
// const CustID= localStorage.getItem("CustID");

//  const CompanyName = sessionStorage.getItem("CompanyName");

 function WebEncryptData(TextValue, ActionType){
  var config = {
      method: 'post',
      url: APIURL + '/api/Web_Text_EncodeDecode?textForEncodeDecode='+ TextValue +'&ActionType='+ActionType,
      headers: {
        'Authorization': 'Basic ' + APIConfig
      }
    };
   return  axios(config)
}
async function WebGetCompanyDetail(){
  var config = {
      method: 'post',
      async: false,  
      url: APIURL+'/api/WebCompanyInfo?accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}


// async function WebGetUserLogin(strUserID,strPWD){

//   var config = {
//       method: 'post',
//       url: APIURL+'/api/WebCustomerLogin?strUserID='+strUserID+'&strPWD='+strPWD+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,

//       headers: {
//         'Authorization': 'Basic '+APIConfig
//       }
//     };
//    return await axios(config)
// }

async function WebGetUserLogin(strUserID,strPWD){

   var config = {
       method: 'post',
       url: APIURL+'/api/CldPubUserLogin?strUserID='+strUserID+'&strPWD='+strPWD+'&action&FinancialPeriod=&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,

       headers: {
         'Authorization': 'Basic '+APIConfig
       }
     };
    return await axios(config)
 }
 async function WebTodaySaying(){
var TodayQuoteID = 0;
  var config = {
      method: 'post',
      url: APIURL+'/api/WebTodaySaying?TodayQuoteID='+TodayQuoteID+'&iCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,

      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function GetWebGetContactUs(str_UserName,str_Email, str_Phone, str_Message, UserID, Enquiry, sendEnquiryToEmail){   
    var config = {
        method: 'post',
        url: APIURL+'/api/WebContactUs?str_UserName='+str_UserName+'&str_Email='+str_Email+'&str_Phone='+ str_Phone +
        '&str_Message='+str_Message+'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&UserID='+UserID+'&accessCode='+accessCode+
        '&Enquiry='+Enquiry+'&sendEnquiryToEmail='+sendEnquiryToEmail,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}

async function GetSubscriptionOnDemand(websearchtype,websearchText,CategoryID,SiteName,SchoolCode,PageNum,PageSize){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebTitleSearch?websearchtype='+websearchtype+'&websearchText='+websearchText+'&OtherCountry=INDIA&iCompanyID='+iCompanyID+
      '&iBranchID='+iBranchID+'&CategoryID='+CategoryID+'&SiteName='+SiteName+'&SchoolCode='+SchoolCode+'&accessCode='+accessCode+'&PageNum='+PageNum+
      '&PageSize='+PageSize,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebgetDataforDispatch(strActionType,strCol1,strCol2,strCol3,strCol4,strCol5,strCol6,strCol7,strCol8,strCol9,strCol10){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetDispatchAPI?striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&strFinancialPeriod='+FinancialPeriod+'&accessCode='+accessCode
      +'&strActionType='+strActionType+'&strCol1='+strCol1+'&strCol2='+strCol2+'&strCol3='+strCol3+'&strCol4='+strCol4+'&strCol5='+strCol5+'&strCol6='+strCol6+'&strCol7='+strCol7+'&strCol8='+strCol8+'&strCol9='+strCol9+'&strCol10='+strCol10,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
} 


export {GetWebGetContactUs,WebTodaySaying,WebGetUserLogin,WebGetCompanyDetail,WebEncryptData,GetSubscriptionOnDemand,WebgetDataforDispatch,
}; 
