import React, { useState,useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import Cookies from 'js-cookie'

import BackgroundImage from "./bg2.png";
import Logo from "./logo.png";
import "./Login.css";
import { WebGetUserLogin } from './API';

const Login = () => {

  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const User = Cookies.get("#4Fu!")

  
  useEffect(() => {
    if (User && User !== "") {
        window.location.href = '/Dispatch';
    }
}, [User]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await delay(500);
    console.log(`Username :${inputUsername}, Password :${inputPassword}`);
    if (inputUsername === "" ||inputUsername === undefined ||inputUsername === null ) {
      setShow(true);
    }if ( inputPassword === "" ||inputPassword === undefined ||inputPassword === null) {
      setShow(true);
    }

    WebGetUserLogin(inputUsername, inputPassword).then(result=>{ 
      console.log(result.data) 
          if(result.data[0].Result === "Error"){
            setShow(true);
          }else{
            console.log( result.data[0].UserID)
            Cookies.set('#4Fu!',  result.data[0].UserID)
            window.location.href = 'Dispatch';
            
          }})
    setLoading(false);
  };
  
  const handlePassword = () => {};

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <div
      className="sign-in__wrapper"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
        {/* Header */}
        <img
          className="img-thumbnail mx-auto d-block mb-2"
          src={Logo}
          alt="logo"
        />
        <div className="h4 mb-2 text-center"><strong >Bhavisa Member Log In</strong></div>
        {/* ALert */}
        <br/>
        
        {show ? (
          <Alert
            className="mb-2"
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
          >
            Incorrect username or password.
          </Alert>
        ) : (
          <div />
        )}
        <Form.Group className="mb-2" controlId="username">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            type="text"
            value={inputUsername}
            placeholder="Email"
            onChange={(e) => setInputUsername(e.target.value)}
            required
          />
          
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={inputPassword}
            placeholder="Password"
            onChange={(e) => setInputPassword(e.target.value)}
            required
          />
        </Form.Group>
        {/* <Form.Group className="mb-2" controlId="checkbox">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group> */}
         <br/>
        <br/>
        {!loading ? (
          <Button className="w-100" variant="primary" type="submit">
            Log In
          </Button>
        ) : (
          <Button className="w-100" variant="primary" type="submit" disabled>
            Logging In...
          </Button>
        )}
         <br/>
        <br/>
      
      </Form>
      {/* Footer */}
      <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
        Made by Spring Time Sofrware 
      </div>
    </div>
  );
};

export default Login;
