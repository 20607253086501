import React from 'react';
import Index from './pages/Index';	

//Css 

import './assets/css/order.css';
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-widgets/styles.css";

function App() {
	return (
		<div className="App">			
			<Index /> 
		</div>	
	);
}

export default App;
